import { Component, createSignal, Show } from 'solid-js';
import { BaseModal } from './BaseModal.jsx';
import { Confetti } from '@repo/ui/Confetti';
import { Analytics } from '../../utils/Analytics.js';
import { ValidationErrors } from '../../utils/index.js';
import { Logger } from '@repo/logger';
import { ModalManager } from './ModalManager.jsx';
import {
  Designer,
  DesignerRegistry,
  DesignerIdGenerator,
} from '@repo/designer';
import { ImageBufferUtils } from '@repo/shared';
import {
  createBandFromImageData,
  createBandFromImageId,
} from '~/services/server.js';
import { ModalManagerStore } from '~/features/modals/ModalManager.store';

interface FormData {
  bandname: string;
  banddesc: string;
  phone: string;
  email: string;
}

/* function usePersistentForm<T extends Record<string, any>>(
  key: string,
  initialData: T,
) {
  const persistedData =
    typeof window !== 'undefined' && localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key) as string)
      : initialData;

  const [formData, setFormData] = createSignal<T>(persistedData);

  const updateFormData = (newData: Partial<T>) => {
    const updatedData = { ...formData(), ...newData };
    setFormData(updatedData);

    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(updatedData));
    }
  };

  return [formData, updateFormData] as const;
} */

export const CreateModal: Component = () => {
  const analytics = Analytics.getInstance();
  const logger = Logger.getInstance();
  const [showConfetti, setShowConfetti] = createSignal(false);
  const [formData, setFormData] = createSignal<FormData>({
    bandname: '',
    banddesc: '',
    phone: '',
    email: '',
  });
  const [activeField, setActiveField] = createSignal<keyof FormData | null>(
    null,
  );
  const [completedFields, setCompletedFields] = createSignal<Set<string>>(
    new Set(),
  );
  const [errors, setErrors] = createSignal<ValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = createSignal(false);
  const [formProgress, setFormProgress] = createSignal(0);

  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/\D/g, '');
    if (numbers.length <= 3) return numbers;
    if (numbers.length <= 6)
      return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
  };

  const validateField = (field: keyof FormData, value: string) => {
    const newErrors = { ...errors() };

    switch (field) {
      case 'bandname':
        if (!value.trim()) {
          newErrors.bandname = 'Give your band a memorable name!';
        } else if (value.length > 30) {
          newErrors.bandname = 'Keep it under 30 characters';
        } else {
          delete newErrors.bandname;
        }
        break;

      case 'banddesc':
        if (value.length > 100) {
          newErrors.banddesc = 'Keep your message under 100 characters';
        } else {
          delete newErrors.banddesc;
        }
        break;

      case 'phone':
        if (!value.trim()) {
          newErrors.phone = 'Phone is required to save your design';
        } else if (value && !value.match(/^\d{3}-\d{3}-\d{4}$/)) {
          newErrors.phone = 'Please use format: 555-867-5309';
        } else {
          delete newErrors.phone;
        }
        break;

      case 'email':
        if (!value.trim()) {
          newErrors.email = 'Email is required to save your design';
        } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          newErrors.email = 'Please enter a valid email address';
        } else {
          delete newErrors.email;
        }
        break;
    }

    setErrors(newErrors);
    updateProgress();
    return !newErrors[field];
  };

  const updateProgress = () => {
    const totalFields = 4; // bandname, banddesc, phone, email
    const completedCount = Object.keys(formData()).filter((key) => {
      const value = formData()[key as keyof FormData];
      return value && !errors()[key];
    }).length;
    setFormProgress((completedCount / totalFields) * 100);
  };

  const handleFocus = (field: keyof FormData) => {
    setActiveField(field);
    analytics.trackEvent({
      category: 'Form',
      action: 'FieldFocus',
      metadata: { field },
    });
  };

  const handleBlur = (field: keyof FormData) => {
    setActiveField(null);
    validateField(field, formData()[field]);
  };

  const handleInputChange = (field: keyof FormData, value: string) => {
    let processedValue = value;
    if (field === 'phone') {
      processedValue = formatPhoneNumber(value);
    }

    setFormData((prev) => ({ ...prev, [field]: processedValue }));
    /* setFormData({ [field]: value }); */
    validateField(field, processedValue);

    if (processedValue && !errors()[field]) {
      setCompletedFields((prev) => new Set(prev).add(field));
    }
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const allFields: (keyof FormData)[] = [
      'bandname',
      'banddesc',
      'phone',
      'email',
    ];
    let isValid = true;

    allFields.forEach((field) => {
      if (!validateField(field, formData()[field])) {
        isValid = false;
      }
    });

    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    try {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 500));
      const input = formData();

      const designerRegistry = DesignerRegistry.getInstance();
      const designerId = DesignerIdGenerator.create('main-designer');
      const designer: Designer = designerRegistry.getInstance(designerId)!;
      designer.controller.state.previewMode = true;
      designer.controller.render();
      const canvas = designer.container.buffer!;
      const data = await ImageBufferUtils.canvasToJpgArrayBuffer(canvas);
      designer.controller.state.previewMode = false;
      designer.controller.render();

      // TODO(pbirch): Register user with Klavio API to send email and SMS
      logger.warn('[CreateModal] Need to register user with Klavio', { input });
      // const klavio = new KlavioClient();
      // klavio.registerUser({
      //   email: input.email,
      //   phone: input.phone,
      //   bandname: input.bandname,
      //   banddesc: input.banddesc,
      // });
      const response = await createBandFromImageData({
        id: crypto.randomUUID(),
        title: input.bandname,
        description: input.banddesc,
        email: input.email,
        phone: input.phone,
        data,
      });
      if (response.result.type === 'error') {
        logger.error(
          '[PurchaseModal] Failed to initiate purchase',
          response.result.error,
          {
            input,
          },
        );
        const newErrors = { ...errors() };
        newErrors.form = response.result.error;
        setErrors(newErrors);
        // TODO(pbirch): Show error message to user
        return;
      }

      // const result = await createBandFromImageId({
      //   id: 'asdf',
      //   title: 'asdf',
      //   description: 'asdf',
      //   imageName: '78086e68-642f-4c26-90bb-3689eef1f36d'
      // });

      // Show confetti on success
      console.warn('Submitting form:', formData());

      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Hide after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const store = ModalManagerStore.getInstance();

      await designer.reset();

      /* 
      store.closeModal();
      store.openModal('MODAL_PURCHASE', {
        designId: response.result.value.product.productSet.product.handle,
        previewUrl: response.result.value.upload.url.toString(),
      }); */

      analytics.trackEvent({
        category: 'Form',
        action: 'Submit',
        metadata: { formData: input },
      });

      const designId = response.result.value.product.productSet.product.handle;
      const previewUrl = response.result.value.upload.url.toString();

      analytics.trackEvent({
        category: 'Purchase',
        action: 'Initiated',
        metadata: {
          designId,
          previewUrl,
        },
      });

      if (response.result.type === 'success') {
        window.open(`https://shop.wearshare.com/products/${designId}`, '_self');
      }

      // Handle success (e.g., close modal, show success message)
    } catch (error) {
      console.error('Submission error:', error);
      const newErrors = { ...errors() };
      if (error instanceof Error) {
        newErrors.form = error.message;
      } else {
        newErrors.form = error as string;
      }
      setErrors(newErrors);
    } finally {
      setIsSubmitting(false);
      setFormData({
        bandname: '',
        banddesc: '',
        phone: '',
        email: '',
      });
    }
  };
  updateProgress();

  return (
    <>
      <BaseModal
        key="MODAL_CREATE"
        title="Create Your Band"
        description="Design your custom Wearshare band"
        size="medium"
      >
        <Confetti active={showConfetti()} />
        <div class="px-4 py-2">
          {/* Progress bar */}
          <div class="h-2 bg-gray-200 rounded-full mb-6 overflow-hidden">
            <div
              class="h-full bg-[#fbff53] transition-all duration-500 ease-out"
              style={{ width: `${formProgress()}%` }}
            />
          </div>

          <h2 class="font-roboto text-3xl max-[600px]:text-2xl font-bold mb-8 uppercase text-center">
            Save & Personalize Your
            <br />
            Wearshare Photo Bandz
          </h2>

          <form class="space-y-6" onSubmit={handleSubmit}>
            {/* Band Name Input */}
            <div>
              <div class="relative">
                <input
                  type="text"
                  name="bandname"
                  value={formData().bandname}
                  disabled={isSubmitting()}
                  placeholder="TITLE YOUR DESIGN"
                  class={`placeholder:font-bold font-roboto form-input w-full px-4 py-2
                                        border-black border-[3px] rounded-lg placeholder-black text-black text-center font-semibold
                                        transition-all duration-200
                                        ${errors().bandname ? 'border-red-500 animate-shake' : ''}`}
                  onFocus={() => handleFocus('bandname')}
                  onBlur={() => handleBlur('bandname')}
                  onChange={(e) =>
                    handleInputChange('bandname', e.currentTarget.value)
                  }
                />
                <Show
                  when={completedFields().has('bandname') && !errors().bandname}
                >
                  <div class="absolute right-3 top-1/2 -translate-y-1/2">
                    <svg
                      class="w-5 h-5 text-green-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                </Show>
              </div>
              <Show when={errors().bandname}>
                <p class="mt-1 text-red-500 text-sm text-center">
                  {errors().bandname}
                </p>
              </Show>
            </div>

            {/* Secret Message */}
            <div>
              <div class="flex justify-between items-center mt-1">
                <p class="text-sm font-roboto font-semibold uppercase">
                  Add a hidden message, revealed through the QR code scan
                </p>
                <span class="text-sm text-gray-500">
                  {formData().banddesc.length}/100
                </span>
              </div>
              <div class="relative">
                <textarea
                  name="banddesc"
                  value={formData().banddesc}
                  disabled={isSubmitting()}
                  placeholder="SECRET MESSAGE"
                  rows={3}
                  class={`placeholder:font-bold font-roboto form-input w-full px-4 py-2
                                        border-black border-[3px] rounded-lg placeholder-black text-black text-center font-semibold
                                        transition-all duration-200
                                        ${errors().banddesc ? 'border-red-500 animate-shake' : ''}`}
                  onFocus={() => handleFocus('banddesc')}
                  onBlur={() => handleBlur('banddesc')}
                  onChange={(e) =>
                    handleInputChange('banddesc', e.currentTarget.value)
                  }
                />
              </div>
            </div>

            {/* Phone Input */}
            <div>
              <div class="relative">
                <input
                  type="tel"
                  name="phone"
                  value={formData().phone}
                  disabled={isSubmitting()}
                  placeholder="PHONE NUMBER (REQUIRED)"
                  required
                  class={`placeholder:font-bold font-roboto form-input w-full px-4 py-2
                                        border-black border-[3px] rounded-lg placeholder-black text-black text-center font-semibold
                                        transition-all duration-200
                                        ${errors().phone ? 'border-red-500 animate-shake' : ''}`}
                  onFocus={() => handleFocus('phone')}
                  onBlur={() => handleBlur('phone')}
                  onChange={(e) =>
                    handleInputChange('phone', e.currentTarget.value)
                  }
                />
              </div>
              {/* <p class="mt-1 text-sm font-roboto font-semibold text-center uppercase">
                Get your band design link sent directly to you via text.
              </p> */}
              <Show when={errors().phone}>
                <p class="mt-1 text-red-500 text-sm text-center">
                  {errors().phone}
                </p>
              </Show>
            </div>

            {/* Email Input */}
            <div>
              <div class="relative">
                <input
                  type="email"
                  name="email"
                  value={formData().email}
                  disabled={isSubmitting()}
                  placeholder="EMAIL ADDRESS (REQUIRED)"
                  required
                  class={`placeholder:font-bold font-roboto form-input w-full px-4 py-2
                                        border-black border-[3px] rounded-lg placeholder-black text-black text-center font-semibold
                                        transition-all duration-200
                                        ${errors().email ? 'border-red-500 animate-shake' : ''}`}
                  onFocus={() => handleFocus('email')}
                  onBlur={() => handleBlur('email')}
                  onChange={(e) =>
                    handleInputChange('email', e.currentTarget.value)
                  }
                />
              </div>
              {/* <p class="mt-1 text-sm font-roboto font-semibold text-center uppercase">
                We'll send your design link to this address.
              </p> */}
              <Show when={errors().email}>
                <p class="mt-1 text-red-500 text-sm text-center">
                  {errors().email}
                </p>
              </Show>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting() || formProgress() < 100}
              class={`font-roboto font-bold w-full py-2 border-black border-[3px] 
                                rounded-lg uppercase text-center transition-all duration-200
                                ${
                                  formProgress() === 100
                                    ? 'bg-[#bfec09] hover:bg-[#bfec09] text-black'
                                    : 'bg-gray-100 text-gray-500'
                                }
                                disabled:opacity-75`}
            >
              {isSubmitting() ? (
                <span class="flex items-center justify-center space-x-2">
                  <div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
                  <span>Saving...</span>
                </span>
              ) : (
                'SAVE MY BAND'
              )}
            </button>

            <Show when={errors().form}>
              <p class="mt-1 text-red-500 text-sm text-center">
                {errors().form}
              </p>
            </Show>

            <p class="text-sm font-roboto font-semibold text-center uppercase">
              Design changes require creating a new band.
            </p>
          </form>
        </div>
      </BaseModal>
    </>
  );
};
